<template>
  <NuxtLink
    v-if="$props.to"
    :to="to"
    :class="buttonClass"
    :target="target"
  >
    <!-- <SVGarrow v-if="arrowLeft" class="inline pl-1 rotate-180" /> -->
    <slot />
    <!-- <SVGarrow v-if="arrowRight" class="inline pl-1" /> -->
  </NuxtLink>
  <Component
    :is="tag"
    v-else
    :class="buttonClass"
    @click="$emit('click')"
  >
    <!-- <SVGarrow v-if="arrowLeft" class="inline pl-1 rotate-180" /> -->
    <slot />
    <!-- <SVGarrow v-if="arrowRight" class="inline pl-1" /> -->
  </Component>
</template>

<script>
// @group elements
export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    to: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'primary'
    },
    variantType: {
      type: String,
      default: 'normal'
    },
    target: {
      type: String,
      default: null
    },
    arrowRight: {
      type: Boolean,
      default: false
    },
    arrowLeft: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClass() {
      switch (this.variant) {
        case 'primary':
          switch (this.variantType) {
            case 'normal':
              return 'btn primary-normal';
            case 'color':
              return 'btn primary-color';
            case 'transparent':
              return 'btn primary-transparent';
            default:
              break;
          }
          break;
        case 'secondary':
          switch (this.variantType) {
            case 'normal':
              return 'btn secondary-normal';
            case 'color':
              return 'btn secondary-color';
            default:
              break;
          }
          break;
        default:
          break;
      }
      return null;
    }
  }
});
</script>

<style lang="postcss">
.btn {
  @apply py-2 lg:py-3 no-underline text-center inline-flex items-center justify-center px-2;
  @apply transition pointer-events-auto rounded-full;
  @apply items-center border border-transparent;
  @apply min-w-[150px] max-h-[56px] text-[10px] uppercase font-[461];
  letter-spacing: 1.3px;
  font-weight: 461;
}

/* exceptions for pages with BG */
/* .bg-secondary,
.bg-rose,
.bg-mint {
  .btn {
    @apply bg-green;
    &:hover {
      @apply bg-greenDark text-white;
    }
  }
} */

/* PRIMARY BUTTON */
.primary-normal {
  @apply bg-green text-white border-transparent border-[0.5px] border-white border-opacity-20 px-3;
  @apply hover:bg-greenDark;

  svg path,
  rect {
    @apply stroke-[white];
  }

  &:hover,
  &:active {
    svg path,
    rect {
      @apply stroke-[white];
    }
  }
}

.primary-transparent {
  @apply bg-transparent text-white border-transparent border-[0.2px] border-white border-opacity-20;
  @apply hover:bg-secondary hover:border-none hover:text-green;

  svg path,
  rect {
    @apply stroke-[white];
  }

  &:hover,
  &:active {
    svg path,
    rect {
      @apply stroke-[white];
    }
  }
}

.primary-color {
  @apply bg-white text-main;
  @apply hover:border-white hover:bg-main hover:text-white;

  svg path,
  rect {
    @apply stroke-[white];
  }

  &:hover,
  &:active {
    svg path,
    rect {
      @apply stroke-[white];
    }
  }
}

/* SECONDARY BUTTON */
.secondary-normal {
  @apply bg-transparent border-main text-main border-[0.2px];
  @apply hover:bg-greenDark hover:text-white;

  svg path,
  rect {
    @apply stroke-green;
  }

  &:hover,
  &:active {
    svg path,
    rect {
      @apply stroke-[white];
    }
  }
}

.secondary-color {
  @apply bg-transparent border-white text-white;
  @apply hover:bg-main hover:text-white;

  svg path,
  rect {
    @apply stroke-[black];
  }

  &:hover,
  &:active {
    svg path,
    rect {
      @apply stroke-[white];
    }
  }
}
</style>
